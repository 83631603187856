import * as React from 'react'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'

export enum ButtonVariant {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  GHOST = 'ghost',
  LINK = 'link'
}

const getVariantStyling = (variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.DEFAULT:
      return css`
        color: ${props => props.theme.colors.primary[500]};
        background: transparent;

        &:hover {
          outline: 1px solid ${props => props.theme.colors.primary[200]};
        }
      `
    case ButtonVariant.PRIMARY:
      return css`
        color: white;
        background: ${props => props.theme.colors.primary[500] || props.theme.colors.primary};
      `
    case ButtonVariant.SECONDARY:
      return css`
        color: ${props => props.theme.colors.primary[700] || props.theme.colors.primary};
        background: ${props => props.theme.colors.light};
        border: 1px solid;
        border-color: ${props => props.theme.colors.primary[200] || props.theme.colors.primary};
      `
    case ButtonVariant.GHOST:
      return css`
        color: ${props => props.theme.colors.primary[700] || props.theme.colors.primary};
        background: ${props => props.theme.colors.transparent};
        border: 2px solid;
        border-color: ${props => props.theme.colors.primary[200] || props.theme.colors.primary};
      `
    case ButtonVariant.LINK:
      return css`
        background: ${props => props.theme.colors.transparent};
        border: 2px solid;
        border-color: transparent;
        text-decoration: underline;
      `
    case ButtonVariant.ERROR:
      return css`
        color: white;
        background: ${props => props.theme.colors.error['600']};
        border: 1px solid;
        border-color: ${props => props.theme.colors.error['600']};
      `
  }
}

const Component = styled.button<{
  variant: ButtonVariant
}>`
  position: relative;
  border: 0;
  margin: 0;
  padding: .5rem 0.5rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  ${props => getVariantStyling(props.variant)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .45);
  display: flex;
  align-items: center;
  justify-content: center;
`
interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'button' | 'submit'
  children: ReactNode
  isLoading?: boolean
  variant?: ButtonVariant
  iconBefore?: ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  title?: string
}

export const Button = ({
  type = 'button',
  iconBefore,
  children,
  isLoading,
  variant = ButtonVariant.DEFAULT,
  title,
  onClick,
  disabled
}: IButtonProps) => {
  return (
    <Component
      type={type}
      variant={variant}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      {isLoading && (
        <Loader>
          ...
        </Loader>
      )}

      {iconBefore && (iconBefore)}
      {children}
    </Component>
  )
}

Button.Variant = ButtonVariant
Button.Styled = Component

export default Button
