import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import styled from 'styled-components'

import Image from './../../Blocks/Image/Image'
import RichText from './../../Blocks/RichText/RichText'

type FeedbackComponent = React.FC<IFeedback> & {
  schema: IFieldSchema
}

const Base = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 0.8em;
`
const ImageWrapper = styled.div`
  max-width: 200px;
  margin: 0 auto;
`
export interface IFeedback {
  disabled?: boolean
  image: string
  title?: {
    general: string
    wrong: string
    correct: string
  }
  content: string
  isCorrect?: boolean
  hasCorrect?: boolean
}

export const Feedback: FeedbackComponent = ({ image, content, title, hasCorrect, isCorrect, disabled }: IFeedback) => {

  const getFeedbackTitle = (isCorrect, hasCorrect, title) => {

    let newTitle = title?.general

    if (hasCorrect) {
      if (isCorrect && title?.correct) {
        newTitle = title.correct
      } else if (!isCorrect && title?.wrong) {
        newTitle = title.wrong
      }
    }

    if (newTitle) {
      return (
        <RichText
          align='center'
          content={[{
            format: 'subtitle',
            text: newTitle
          }]}
        />
      )
    } else {
      return null
    }

  }

  if (disabled) return null

  return (
    <Base>
      {image && <ImageWrapper><Image image={image} /></ImageWrapper>}
      {title && getFeedbackTitle(isCorrect, hasCorrect, title)}

      {content && (
        <RichText
          align='center'
          content={[{
            format: 'default',
            text: content.toString() // TODO temp hack for the content
          }]}
        />
      )}
    </Base>
  )
}

Feedback.schema = {
  name: 'Feedback',
  key: 'feedback',
  defaultFieldValues: {
  },
  fields: [
    {
      key: 'disabled',
      type: FieldType.BOOLEAN,
      label: 'Disable Feedback',
      instructions: 'Check to disable the feedback'
    },
    {
      key: 'image',
      type: FieldType.MEDIA,
      label: 'Image',
      maxFiles: 1,
      mediaType: MediaType.IMAGE
    },
    {
      key: 'title',
      type: FieldType.GROUP,
      collapsible: false,
      label: 'Title',
      fields: [
        {
          key: 'general',
          type: FieldType.TEXT,
          label: 'General'
        },
        {
          key: 'correct',
          type: FieldType.TEXT,
          label: 'Correct'
        },
        {
          key: 'wrong',
          type: FieldType.TEXT,
          label: 'Wrong'
        }
      ]
    },
    {
      key: 'content',
      type: FieldType.TEXT,
      label: 'Content',
      instructions: 'Bold (**...**), Italic (*...*), Accent (##...##)'
    }
  ]
}

export default Feedback
