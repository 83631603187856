/* eslint-disable react/no-unused-prop-types */
import transitions from '@pidk/common/src/lib/transitions'
import SFX from '@pidk/common/src/sfx'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import useProjectMedia from '@pidk/renderer/src/hooks/useProjectMedia'
import { Reorder as MotionReorder } from 'framer-motion'
import type { ReactNode } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import useSound from 'use-sound'

import useRendererContext from '../../../hooks/useRendererContext'
import { Actions, ActionButton } from './../../common/Actions'
import type { IFeedback } from './../../common/Feedback'
import Feedback from './../../common/Feedback'
import Option, { Image } from './components/Option'
import useSort from './useSort'

type BlockComponent = React.FC<ISort> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}
interface IOption {
  label: string
  correct: boolean
  image?: string
}

interface ISort {
  id: string
  layout: 'horizontal' | 'vertical'
  type: 'text' | 'text-image' | 'image'
  options: IOption[]
  feedback?: IFeedback
  onPopup?: (type: string, component: ReactNode, timeout, closable) => void
}

const Base = styled.div<any>`
  width: 100%;
`

const Grid = styled.div<any>`
  display: flex;
  flex-direction: ${props => (props.$layout === 'horizontal' ? 'row' : 'column')};
  justify-content: center;
  width: 100%;
  gap: 12px;

  span.dnd-item {
    flex: 1 1 auto;
    max-width: ${props => props.$type === 'text' ? 'auto' : '250px'};
  }

  ${Option.Styled} {
      width: 100%;
      figure {
        pointer-events: none;
      }
  }
`

const Reorder: BlockComponent = ({
  id, layout, type, options, feedback, onPopup
}: ISort) => {

  const { isEditable } = useRendererContext()
  const [isCorrect, setFeedback] = useState(null)
  const [errors, setErrors] = useState([])
  const optionImages = options?.map(o => o.image)
  const images = useProjectMedia(optionImages)

  const [errorSFX] = useSound(SFX.negativeDongs)
  const [errorAlertSFX] = useSound(SFX.negativeAlert)
  const [correctSFX] = useSound(SFX.correctAlert)
  const [hoverSFX] = useSound(SFX.click, {
    volume: 0.1
  })

  const handleCheckedAnswer = (correct, payload) => {

    setFeedback(correct)
    setErrors(payload.selection)

    if (correct === false && attemptCount < 1) {
      errorSFX()
      return onPopup('dialog', <Feedback
        isCorrect={correct}
        hasCorrect
        title={feedback.title}
        content='Probeer het nog een keertje!'
        image={feedback?.image}
                               />, 600, false)
    } else if (correct === false && attemptCount >= 1) {
      errorAlertSFX()
      actions.revealCorrectAnswer()
      return onPopup('dialog', <Feedback
        isCorrect={correct}
        hasCorrect
        title={feedback.title}
        content='Dit zijn de juiste antwoorden.'
        image={feedback?.image}
                               />, 1000, false)
    } else {
      correctSFX()
      if (feedback?.title && !feedback?.disabled) {
        onShowFeedback(true)
      }

    }
  }

  // eslint-disable-next-line
  const { state, selection, actions, attemptCount } = useSort({ id, options: options, shuffle: !isEditable, onChecked: handleCheckedAnswer })

  const onShowFeedback = (correct) => {
    return onPopup('dialog', <Feedback
      isCorrect={correct}
      hasCorrect
      title={feedback.title}
      content={feedback?.content}
      image={feedback?.image}
                             />, null, true)
  }

  return (
    <Base>

      <MotionReorder.Group
        as='div'
        values={options}
        onReorder={(e) => { actions.handleReorder(e), hoverSFX() }}
        layoutScroll
        axis={(layout === 'horizontal' ? 'x' : 'y')}
      >
        <Grid
          $layout={layout}
          $type={type}
        >
          {selection?.map((c, i) => {

            const key = c.label

            // TODO maybe nicer to cleanup with hashmap for all images with id and url
            const optionImage = (type !== 'text' && images.length > 0 ? images.filter(i => i?.id === c?.image) : false)

            const isCorrect = (state === 'results' || state === 'feedback' ? !errors.includes(c) : false)

            return (

              <MotionReorder.Item
                as='span'
                key={key}
                value={c}
                className='dnd-item'
                dragListener={state !== 'feedback'}
              >
                <Option
                  key={c.label + '' + i}
                  label={i + 1 + ''}
                  isSelected={(state === 'feedback' || isCorrect)}
                  isCorrect={isCorrect}
                  state={state}
                >
                  {optionImage && (
                    <Image>
                      <img src={optionImage[0]?.url} />
                    </Image>
                  )}

                  {type !== 'image' &&
                    <span className='label'>{c.label}</span>}
                </Option>
              </MotionReorder.Item>
            )
          }
          )}
        </Grid>
      </MotionReorder.Group>

      <Actions>
        {state === 'selected' && (
          <ActionButton
            key='action-check'
            onClick={(e) => actions.checkAnswer(e)}
            {...transitions.fadeUp}
          >Controleer antwoord
          </ActionButton>
        )}

        {feedback && ((!isCorrect && attemptCount > 1) || isCorrect) && (
          <ActionButton
            key='action-feedback'
            {...transitions.fadeUp}
            onClick={() => { actions.reset(), setFeedback(null) }}
          >Opnieuw spelen
          </ActionButton>
        )}
      </Actions>
    </Base>
  )
}

Reorder.schema = {
  name: 'Reorder',
  key: 'reorder',
  defaultFieldValues: {
    type: 'text',
    layout: 'horizontal',
    options: [
      {
        label: 'Optie 1',
        correct: false
      }, {
        label: 'Optie 2',
        correct: false
      }
    ]
  },
  fields: [
    {
      key: 'type',
      type: FieldType.CHOICE,
      label: 'Type',
      options: [
        {
          value: 'text',
          label: 'Text'
        },
        {
          value: 'text-image',
          label: 'Text Image'
        },
        {
          value: 'image',
          label: 'Image'
        }
      ]
    },
    {
      key: 'layout',
      type: FieldType.CHOICE,
      label: 'Layout',
      component: 'button',
      options: [
        {
          value: 'horizontal',
          label: 'Horizontal'
        },
        {
          value: 'vertical',
          label: 'Vertical'
        }
      ]
    },
    {
      key: 'options',
      type: FieldType.REPEATER,
      label: 'Opties',
      primaryKey: 'label',
      button: 'Add option',
      collapsible: false,
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label'
        }, {
          key: 'image',
          type: FieldType.MEDIA,
          label: 'Image',
          maxFiles: 1,
          mediaType: MediaType.IMAGE,
          conditional: [{
            field: 'type',
            operator: '!=',
            value: 'text'
          }]
        }
      ]
    },
    {
      key: 'feedback',
      type: FieldType.GROUP,
      label: 'Feedback',
      primaryKey: 'content',
      fields: [...Feedback.schema.fields]
    }
  ]
}

Reorder.Styled = Base

export default Reorder
