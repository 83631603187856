import { Button } from '@pidk/common/src'
import { PersonIcon } from '@pidk/common/src/components/Icons'
import Modal, { ModalActions, ModalDescription } from '@pidk/common/src/components/Modal/Modal'
import styled from 'styled-components'
import type { RoomParticipant } from 'vuurrood-sockets'

import useSocket from '../../hooks/useSocket'

const RoomInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`
const RoomCode = styled.strong`
  display: flex;
  gap: 0.5rem;

  span {
    display: inline-block;
    font-size: 2rem;
    width: 2rem;
    background-color: black;
    color: white;
  }
`
const RoomParticipants = styled.strong`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 2rem;
`

interface IRoomModal {
  onDeactivateRoom: () => Promise<any>
  onClose: () => void
}

const RoomModal = ({ onClose, onDeactivateRoom }: IRoomModal) => {
  const socketContext = useSocket()
  const voteUrl = `${window.location.hostname}/stem`
  const amountParticipants = socketContext.state.room?.participants.reduce((output: number, p: RoomParticipant) => {
    if (p.isConnected) {
      return output + 1
    }

    return output
  }, 0)

  return (
    <Modal
      type='dialog'
      onClose={onClose}
    >
      <ModalDescription>
        <p>Ga naar <strong>{voteUrl}</strong><br />Voer de onderstaande code in:</p>
        <RoomInfo>
          <RoomCode>
            {socketContext.state.room?.code.split('').map((digit, index) => (
              <span key={index}>{digit}</span>
            ))}
          </RoomCode>
          <RoomParticipants><PersonIcon
            width={24}
            height={24}
                            />{amountParticipants}
          </RoomParticipants>
        </RoomInfo>
        <small>Zijn alle deelnemers aanwezig? Klik op de button en ga aan de slag.</small>
      </ModalDescription>
      <ModalActions $direction='column'>
        <Button
          variant={Button.Variant.PRIMARY}
          onClick={onClose}
        >
          Aan de slag
        </Button>
        <Button
          variant={Button.Variant.LINK}
          onClick={onDeactivateRoom}
        >
          Doorgaan zonder eigen devices
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default RoomModal
