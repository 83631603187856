/* eslint-disable react/no-unused-prop-types */
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import useProjectMediaMap from '@pidk/renderer/src/hooks/useProjectMediaMap'
import type { ReactNode } from 'react'

import { Actions, ActionButton } from './../../common/Actions'
import type { IFeedback } from './../../common/Feedback'
import Feedback from './../../common/Feedback'
import DraggableItem from './components/DraggableItem'
import Option from './components/Option'
import {
  Base,
  Grid,
  Dropzone,
  Dragzone,
  Label
} from './Connect.styled'
import useConnect from './useConnect'

type BlockComponent = React.FC<IConnect> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}
interface IOption {
  label: string
  image?: string
}
interface IZone {
  id: string
  label: string
  image?: string
  option: IOption
}
interface IConnect {
  id: string
  type: 'text' | 'text-image' | 'image'
  zones: IZone[]
  feedback?: IFeedback
  isEditable?: boolean
  onPopup?: (type: string, component: ReactNode, timeout, closable) => void
}

const Connect: BlockComponent = ({
  id, type, zones, feedback, isEditable, onPopup
}: IConnect) => {

  const images = useProjectMediaMap()

  const onShowFeedback = () => {
    if (feedback?.disabled) return
    return onPopup('dialog', <Feedback
      title={feedback?.title}
      content={feedback?.content}
      image={feedback?.image}
                             />, null, true)
  }

  // eslint-disable-next-line
  const { state, options, dragOverZone, refs, selection, actions, isDragging } = useConnect({ preview: isEditable, id, zones, onDone: () => onShowFeedback() })

  return (
    <Base>
      <Grid
        $layout='horizontal'
        $type={type}
        ref={refs['constraintsRef']}
      >
        <Grid
          $layout='vertical'
        >
          {zones && zones.map((c, i) => {

            return (

              <Dropzone
                key={`dropzone-${c.id}`}
                ref={refs['dropzonesRef'][c.id]}
                $isActive={dragOverZone === c.id && isDragging == true}
                $isDone={selection[c.id] || false}
              >

                {selection[c.id] ? (
                  <DraggableItem
                    key={`draggable-${selection[c.id].id}`}
                    id={`draggable-${selection[c.id].id}`}
                    index={i}
                    constraints={refs['constraintsRef']}
                    actions={actions}
                    value={selection[c.id]}
                    drag={false}
                  >
                    <Option
                      key={`option-${selection[c.id].id}`}
                      isSelected
                      isCorrect
                      label={c.label}
                      state='selected'
                      image={c.image ? images[c.image]?.url : false}
                    >

                      {selection[c.id].image && (
                        <figure className='background'><img src={images[selection[c.id].image]?.url} /></figure>
                      )}

                      <Label>{selection[c.id].label}</Label>
                    </Option>
                  </DraggableItem>
                )
                  : (
                    <Option
                      state='init'
                    >
                      {c.image && (
                        <figure className='background'><img src={images[c.image]?.url} /></figure>
                      )}
                      <Label>{c.label}</Label>
                    </Option>
                  )}
              </Dropzone>
            )
          }
          )}
        </Grid>
        <Grid
          $layout='vertical'
        >
          {options && options.map((o, i) => {

            return (
              <Dragzone
                key={`dragzone-${i}`}
              >
                {o !== null && (
                  <DraggableItem
                    key={`draggable-${o.id}`}
                    id={`draggable-${o.id}`}
                    index={i}
                    constraints={refs['constraintsRef']}
                    actions={actions}
                    value={o}
                    drag
                  >
                    <Option
                      key={`option-${o.id}`}
                      isSelected={false}
                      state={state}
                    >
                      {o.image && (
                        <figure className='background'><img src={images[o.image]?.url} /></figure>
                      )}
                      <Label>{o.label}</Label>
                    </Option>
                  </DraggableItem>
                )}
              </Dragzone>
            )

          })}
        </Grid>
      </Grid>
      {Object.keys(selection).length === options?.length && (
        <Actions>
          <ActionButton
            as='button'
            onClick={() => actions.reset()}
          >Opnieuw spelen
          </ActionButton>
        </Actions>
      )}
    </Base>
  )
}

Connect.schema = {
  name: 'Connect',
  key: 'connect',
  defaultFieldValues: {
    type: 'text'
  },
  fields: [
    {
      key: 'zones',
      type: FieldType.REPEATER,
      label: 'Zones',
      primaryKey: 'label',
      collapsible: false,
      button: 'Add zone',
      fields: [
        {
          key: 'label',
          type: FieldType.TEXT,
          label: 'Label'
        },
        {
          key: 'image',
          label: 'Image',
          type: FieldType.MEDIA,
          maxFiles: 1,
          mediaType: MediaType.IMAGE
        },
        {
          key: 'option',
          type: FieldType.GROUP,
          label: 'Option',
          primaryKey: 'label',
          collapsible: false,
          fields: [
            {
              key: 'label',
              type: FieldType.TEXT,
              label: 'Label'
            },
            {
              key: 'image',
              label: 'Image',
              type: FieldType.MEDIA,
              maxFiles: 1,
              mediaType: MediaType.IMAGE
            }
          ]
        }
      ]
    },
    {
      key: 'feedback',
      type: FieldType.GROUP,
      label: 'Feedback',
      primaryKey: 'content',
      fields: [...Feedback.schema.fields]
    }
  ]
}

Connect.Styled = Base

export default Connect
