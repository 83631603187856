import { getProjectThemeColor } from '@pidk/renderer/src/utils/project'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Actions = styled(motion.div)`
    position: fixed;
    bottom: 0;
    left: 25%;
    right: 25%;
    display: flex;
    justify-content: center;
    z-index: 20;
    margin-bottom: 2rem;
`

export const ActionButton = styled(motion.button)`
    position: relative;
    cursor: pointer;
    font-family: ${props => props.theme.fonts.fontFamily['heading']};
    border: 0;
    padding: 1rem 2rem;
    background: ${props => getProjectThemeColor(props.theme.colors, props.theme.nav.actionBackgroundColor)};
    color: ${props => getProjectThemeColor(props.theme.colors, props.theme.nav.backgroundColor)};
    font-weight: bold;
    font-size: 20px;
    border-radius: 40px;
    font-family: ${props => props.theme.fonts.fontFamily['buttons']};
`
