import type { StatisticsMetaProjectCheckouts } from '@pidk/api/src/types/api'
import { CheckoutInteractionKey, EventType } from '@pidk/api/src/types/api'
import transitions from '@pidk/common/src/lib/transitions'
import { isDefined } from '@pidk/common/src/utils/isDefined'
import type { IFieldSchema } from '@pidk/compose/src/types/fields'
import { FieldType, MediaType } from '@pidk/compose/src/types/fields'
import useRendererContext from '@pidk/renderer/src/hooks/useRendererContext'
import useSocket from '@pidk/renderer/src/hooks/useSocket'
import { getCurrentDeck } from '@pidk/renderer/src/utils/project'
import { setIsCheckingOut } from '@pidk/web/src/utils/checkout'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { InteractionType, RoomStatus } from 'vuurrood-sockets'

import { ActionButton, Actions } from '../../common/Actions'
import CheckoutIntro from './components/CheckoutIntro'
import CheckoutOutro from './components/CheckoutOutro'
import type { CheckoutScale } from './components/CheckoutQuestion'
import CheckoutQuestion from './components/CheckoutQuestion'
import CheckoutWait from './components/CheckoutWait'

type BlockComponent = React.FC<ICheckout> & {
  schema: IFieldSchema
  Styled?: any // @TODO: can we type this?
}

interface ICheckout {
  id: string
  questions: ICheckoutQuestion[]
  intro: {
    title: string
    button?: string
  }
  outro: {
    title: string
  }
  scale?: CheckoutScale & {
    pretitle?: string
  }
  isHost?: boolean
  currentDeckIdFromInteraction?: string
  onDone?: (event: StatisticsMetaProjectCheckouts) => Promise<void>
}

interface ICheckoutQuestion {
  id: string
  label: string
}

const Component = styled(motion.div)`
  max-width: 50rem;
`

enum CheckoutStateType {
  INTRO = 'INTRO',
  QUESTION = 'QUESTION',
  OUTRO = 'OUTRO',
  AWAITING_INTERACTIONS = 'AWAITING_INTERACTIONS'
}

type CheckoutAnswers = Record<CheckoutInteractionKey, number>

const Checkout: BlockComponent = ({
  intro,
  outro,
  questions,
  isHost,
  scale,
  currentDeckIdFromInteraction,
  onDone
}: ICheckout) => {
  const [checkoutState, setCheckoutState] = useState<CheckoutStateType>(isHost ? CheckoutStateType.INTRO : CheckoutStateType.QUESTION)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
  const [checkoutAnswers, setCheckoutAnswers] = useState<Record<string, CheckoutAnswers>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const isLastQuestion = currentQuestionIndex === questions?.length - 1
  const currentQuestionId = questions?.[currentQuestionIndex]?.id
  const currentQuestion = questions?.find(q => q.id === currentQuestionId)

  const {
    onScopedEventCreate,
    currentDeckId,
    onError,
    rendererState,
    project,
    onRoomCreateInteraction,
    isPreview
  } = useRendererContext()

  const socketContext = useSocket()

  useEffect(() => {
    setIsCheckingOut(true)
  }, [])

  const handleStartCheckout = async () => {
    const currentDeck = getCurrentDeck(project, currentDeckId || currentDeckIdFromInteraction)

    if (isHost && rendererState.hasStarted && currentDeck?.hasVoting && socketContext.state.room?.status === RoomStatus.OPEN) {
      setCheckoutState(CheckoutStateType.AWAITING_INTERACTIONS)

      await onRoomCreateInteraction(socketContext.state.socket.id, socketContext.state.room.code, InteractionType.SURVEY, {
        intro,
        outro,
        questions,
        scale,
        currentDeckId
      })

      return
    }

    setCheckoutState(CheckoutStateType.QUESTION)
  }

  const finishCheckout = () => {
    setCheckoutState(CheckoutStateType.OUTRO)
  }

  const handleNextQuestion = async () => {
    if (checkoutState === CheckoutStateType.INTRO) {
      return setCheckoutState(CheckoutStateType.QUESTION)
    }

    if (isLastQuestion) {
      return setCheckoutState(CheckoutStateType.OUTRO)
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1)
  }

  const handleAnswer = async (interactionKey: CheckoutInteractionKey, value: number) => {
    setCheckoutAnswers(prevState => ({
      ...prevState,
      [currentQuestionId]: {
        ...(prevState[currentQuestionId] || {
          [CheckoutInteractionKey.NEGATIVE]: 0,
          [CheckoutInteractionKey.POSITIVE]: 0,
          [CheckoutInteractionKey.NEUTRAL]: 0
        }),
        [interactionKey]: value
      }
    }))

    if (!isHost) {
      await handleNextQuestion()
    }
  }

  const handleRenderOutro = async () => {
    if (isHost && (!rendererState.hasStarted || isPreview)) {
      return
    }

    setIsSubmitting(true)

    try {
      const eventMeta: StatisticsMetaProjectCheckouts = {
        interactions: Object.entries(checkoutAnswers).map(([id, answers]) => {
          return {
            id,
            label: questions.find(q => q.id === id)?.label,
            answers
          }
        })
      }

      if (!isHost) {
        eventMeta.roomId = socketContext.state.room.id
      }

      await onScopedEventCreate({
        type: EventType.DECK_CHECKOUT,
        deckId: currentDeckId || currentDeckIdFromInteraction,
        meta: eventMeta
      })

      if (onDone) {
        await onDone(eventMeta)
      }

      if (isHost && isDefined(rendererState.checkoutCallback)) {
        await rendererState.checkoutCallback()
      }
    } catch (e) {
      onError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Component
      key={'checkout-' + checkoutState + '-' + currentQuestionId}
      {...transitions.fadeUp}
      transition={{ delay: 0.375 }}
    >
      {isHost && checkoutState === CheckoutStateType.INTRO && (
        <CheckoutIntro
          intro={intro}
          isPreview={isPreview}
          onStartCheckout={handleStartCheckout}
        />
      )}

      {currentQuestion && checkoutState == CheckoutStateType.QUESTION && (
        <>
          <CheckoutQuestion
            question={currentQuestion}
            onAnswer={handleAnswer}
            scale={scale}
            isHost={isHost}
            currentValue={checkoutAnswers?.[currentQuestionId]}
          />

          {isHost && checkoutAnswers && (
            <Actions
              key='actions'
              {...transitions.fadeUp}
              transition={{ delay: 1.5 }}
            >
              <ActionButton
                as='button'
                onClick={handleNextQuestion}
              >
                Verder
              </ActionButton>
            </Actions>
          )}
        </>
      )}

      {isHost && checkoutState === CheckoutStateType.AWAITING_INTERACTIONS && (
        <CheckoutWait onDone={finishCheckout} />
      )}

      {checkoutState === CheckoutStateType.OUTRO && (
        <CheckoutOutro
          outro={outro}
          onRender={handleRenderOutro}
          isSubmitting={isSubmitting}
        />
      )}
    </Component>
  )
}

Checkout.schema = {
  name: 'Checkout',
  key: 'checkout',
  defaultFieldValues: {},
  fields: [{
    key: 'intro',
    type: FieldType.GROUP,
    label: 'Intro',
    fields: [{
      key: 'title',
      type: FieldType.TEXT,
      label: 'Title'
    },
    {
      key: 'button',
      type: FieldType.TEXT,
      label: 'Button'
    }]
  }, {
    key: 'outro',
    type: FieldType.GROUP,
    label: 'Outro',
    fields: [{
      key: 'title',
      type: FieldType.TEXT,
      label: 'Title'
    }]
  }, {
    key: 'questions',
    type: FieldType.REPEATER,
    label: 'Questions',
    primaryKey: 'label',
    fields: [{
      key: 'label',
      type: FieldType.TEXT,
      label: 'Label'
    }]
  }, {
    key: 'scale',
    type: FieldType.GROUP,
    label: 'Scale',
    primaryKey: 'scale',
    fields: [{
      key: 'pretitle',
      type: FieldType.TEXT,
      label: 'Pretitle',
      defaultValue: 'Maak een keuze'
    }, {
      key: 'negative',
      type: FieldType.MEDIA,
      maxFiles: 1,
      mediaType: MediaType.IMAGE,
      label: 'Negative'
    }, {
      key: 'neutral',
      type: FieldType.MEDIA,
      maxFiles: 1,
      mediaType: MediaType.IMAGE,
      label: 'Neutral'
    }, {
      key: 'positive',
      type: FieldType.MEDIA,
      maxFiles: 1,
      mediaType: MediaType.IMAGE,
      label: 'Positive'
    }, {
      key: 'flipScale',
      type: FieldType.BOOLEAN,
      label: 'Flip scale',
      instructions: 'By default the scale is negative, neutral, positive. If you want to flip it, check this box.'
    }]
  }]
}

export default Checkout
