import { EventType } from '@pidk/api/src/types/api'
// import { Button } from '@pidk/common'
import { Button } from '@pidk/common/src'
import { Modal, ModalActions, ModalDescription, ModalTitle } from '@pidk/common/src/components/Modal'
import { RendererView } from '@pidk/renderer/src/types/slide'
import { setDeckStartedInStorage } from '@pidk/renderer/src/utils/event'
import { getCurrentDeck } from '@pidk/renderer/src/utils/project'
import { useState } from 'react'

import useRendererContext from '../../hooks/useRendererContext'
import useSocket from '../../hooks/useSocket'
// import { Button } from '../Slide/Slide.styled'

interface IModalStart {
  onHide: () => void
  deckId: string
}

const ModalStart = ({
  onHide,
  deckId
}: IModalStart) => {
  const { project, onNavigate, setRendererState, onScopedEventCreate, isEditable } = useRendererContext()
  const [isStarting, setIsStarting] = useState<boolean>(false)
  const socketContext = useSocket()

  const handleModalStartOnPreview = async () => {
    const deck = getCurrentDeck(project, deckId) as any
    const slideId = project.decks.find(d => d.id === deck.id)?.slides?.[0]?.id

    await onScopedEventCreate({
      type: EventType.DECK_PREVIEW,
      deckId: deck?.id
    })

    onHide()

    setRendererState(prevState => ({
      ...prevState,
      hasStarted: false,
      hasAskedForStartLesson: true
    }))

    onNavigate(RendererView.DECK, deck.id, slideId)
  }

  const handleModalStartOnStart = async () => {
    const deck = getCurrentDeck(project, deckId) as any
    const slideId = project.decks.find(d => d.id === deck.id)?.slides?.[0]?.id

    setIsStarting(true)

    setDeckStartedInStorage(deck.id)

    await onScopedEventCreate({
      type: EventType.DECK_START,
      deckId: deck?.id
    })

    setIsStarting(false)

    setRendererState(prevState => ({
      ...prevState,
      hasStarted: true,
      hasAskedForStartLesson: true
    }))

    onHide()

    onNavigate(RendererView.DECK, deck.id, slideId)
  }

  if (isEditable) {
    return null
  }

  if (!socketContext.state.isInitialized) {
    return null
  }

  return (
    <Modal
      type='dialog'
      onClose={handleModalStartOnPreview}
    >
      <ModalTitle>
        Aan de slag
      </ModalTitle>
      <ModalDescription>
        Wil je de les eerst voorbereiden? Klik dan op <strong>Voorbereiden</strong>. Ga je met de klas aan de slag? Klik dan op <strong>Starten</strong>.
        <small>Gebruik altijd de F11-toets om de les op volledig scherm te zien.</small>
      </ModalDescription>
      <ModalActions>
        <Button
          variant={Button.Variant.GHOST}
          onClick={handleModalStartOnPreview}
        >
          Voorbereiden
        </Button>
        <Button
          variant={Button.Variant.PRIMARY}
          onClick={handleModalStartOnStart}
          isLoading={isStarting}
        >
          Starten
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ModalStart
