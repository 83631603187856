import { Cross1Icon } from '@pidk/common/src/components/Icons'
import { motion } from 'framer-motion'
import { rgba } from 'polished'
import * as React from 'react'
import type { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Button } from '../Button'
import transitions from './../../lib/transitions'

// TODO: Cleanup seperate files
// TODO: Add Title, Action Components with default styling

const Component = styled(motion.div) <any>`
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: ${props => rgba(props.theme.colors.black, 0.34)};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  overflow-y: auto;
  padding: 2rem;
`

const Inner = styled(motion.div) <any>`
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme }) => theme.shadows['2xl']};
  color: ${({ theme }) => theme.colors.primary};

  ${props => props.$type === 'dialog' && css`
    max-width: 600px;
    height: auto;
    text-align: center;
    margin: auto;
  `}

  ${props => props.$type === 'video' && css`
    max-width: 80vw;
    height: auto;
    text-align: center;
    margin: auto;
  `}

  ${props => props.$type === 'image' && css`
    height: auto;
    text-align: center;
    margin: auto;
    width: auto;
    padding: 0;
  `}

  ${props => props.$type === 'text-image' && css`
    height: auto;
    text-align: center;
    margin: auto;
    width: auto;
  `}

  h2 {
    margin: 0;
    font-size: ${props => props.theme.fonts.fontSize['3xl']};
  }

  p {
    font-size: ${props => props.theme.fonts.fontSize['2xl']};
    margin-bottom: 0;
  }
`

const CloseBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.primary[500] || theme.colors.primary};
  color: ${props => props.theme.colors.light};
  appearance: none;
  border: 0;
  border-radius: 100%;
  padding: 1rem;
  cursor: pointer;
  transform: translate(30%, -30%);
`

export const ModalTitle = styled.h2`
  font-family: ${props => props.theme.fonts.fontFamily.heading};
  font-size: 2rem;
  line-height: 1.25;
`

export const ModalDescription = styled.div`
  font-family: ${props => props.theme.fonts.fontFamily.body};
  color: ${props => props.theme.colors.black};
  font-size: 1.25rem;
  line-height: 1.2;
  margin: .5rem 0;

  small {
    display: block;
    font-size: 1rem;
    color: ${props => props.theme.colors.gray};
    margin-top: 1rem;
  }
`

interface ModalActionsProps {
  $direction?: 'row' | 'column'
}

export const ModalActions = styled.div<ModalActionsProps>`
  display: flex;
  padding: 0 2rem;
  margin-top: 2rem;
  gap: 12px;

  ${props => props.$direction === 'column' && css`
    flex-direction: column;
  `}

  > * {
    flex: 1 1 auto;
    width: 100%;
  }

  ${Button.Styled} {
    height: 3.75rem;
    font-size: 1.25rem;
    justify-content: center;
  }
`

interface IModalProps {
  type: 'video' | 'audio' | 'iframe' | 'dialog' | 'feedback' | 'image' | 'text-image'
  children: ReactNode
  onClose?: () => void
  timeout?: number
}

export const Modal = ({
  type = 'dialog',
  timeout,
  children,
  onClose
}: IModalProps) => {

  const backdropRef = React.useRef(null)
  const contentRef = React.useRef(null)

  const handleClickOutside = (e) => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      onClose()
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const [timer, setTimer] = React.useState(timeout || null)

  React.useEffect(() => {

    if (timeout) {
      let timeLeft = setTimeout(function () {

        if (timer < 0) {
          onClose()
        } else {
          setTimer(timer - 1000)
        }
      }, 1000)

      return () => {
        clearTimeout(timeLeft)
      }

    }

  }, [timer])

  return (
    <Component
      key='modal-bg'
      ref={backdropRef}
      {...transitions['fadeIn']}
      transition={{
        delay: 0
      }}
    >
      <Inner
        ref={contentRef}
        key='modal-content'
        $type={type}
        {...transitions['fadeUp']}
        transition={{
          delay: 0.4
        }}
      >
        {onClose && (
          <CloseBtn onClick={onClose}>
            <Cross1Icon
              width={32}
              height={32}
            />
          </CloseBtn>
        )}
        {children}
      </Inner>
    </Component>
  )
}

Modal.Styled = Component

export default Modal
