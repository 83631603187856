import { CheckoutInteractionKey } from '@pidk/api/src/types/api'
import useProjectMediaMap from '@pidk/renderer/src/hooks/useProjectMediaMap'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import RichText from '../../RichText'
import { Button } from './../../../Slide/Slide.styled'

export type CheckoutScale = Record<CheckoutInteractionKey, string>

interface ICheckoutQuestion {
  currentValue: Record<CheckoutInteractionKey, number>
  question: {
    id: string
    label: string
  }
  isHost: boolean
  scale: CheckoutScale & {
    pretitle?: string
    flipScale?: boolean
  }
  onAnswer: (optionType: string, value: number) => void
}

const Component = styled.div``

const Question = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
  gap: 4rem;

  @media only screen and (max-width: 600px) {
    gap: 1rem;
  }

  > * {
    flex: 1;
  }
`
const Image = styled(motion.button)`
  display: block;
  border-radius: 180px;
  height: 180px;
  width: 180px;
  overflow: hidden;
  border: 0;
  padding: 0;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.xl};

  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const Input = styled.input`
  display: inline-flex;
  height: 4rem;
  width: 8rem;
  text-align: center;
  border-radius: 8px;
  background: white;
  font-size: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.secondary[500]};
`

const getInteractionKeyLabel = (interactionKey: CheckoutInteractionKey): string => {
  switch (interactionKey) {
    case CheckoutInteractionKey.NEGATIVE:
      return 'Negatief'
    case CheckoutInteractionKey.POSITIVE:
      return 'Positief'
    case CheckoutInteractionKey.NEUTRAL:
      return 'Neutraal'
  }
}

const CheckoutQuestion = ({
  question,
  currentValue,
  scale,
  isHost,
  onAnswer
}: ICheckoutQuestion) => {
  const { pretitle } = scale
  const media = useProjectMediaMap()

  const options = !scale.flipScale ? Object.values(CheckoutInteractionKey) : Object.values(CheckoutInteractionKey).reverse()

  return (
    <Component>
      <RichText
        align='center'
        content={[
          {
            format: 'lead',
            text: '**' + question?.label + '**'
          },
          {
            format: 'pretitle',
            text: pretitle || 'Maak een keuze'
          }
        ]}
      />
      <QuestionWrapper>
        {options.map((interactionKey, index) => {
          const mediaId = scale?.[interactionKey]
          const image = media?.[mediaId]
          const value = currentValue?.[interactionKey] ?? 0

          if (!image) return null

          return (
            <Question key={index}>
              {image ? (
                <Image
                  key={'image' + index}
                  whileHover={{ scale: 1.1, rotateZ: '-2deg' }}
                  whileTap={{ scale: 0.8, rotateZ: '-4deg' }}
                  onClick={() => onAnswer(interactionKey, value + 1)}
                >
                  <img
                    src={image.url}
                    alt={interactionKey}
                    height={32}
                    width={32}
                  />
                </Image>
              ) : (
                <Button onClick={() => onAnswer(interactionKey, value + 1)}>
                  {getInteractionKeyLabel(interactionKey)}
                </Button>
              )}

              {isHost && (
                <Input
                  type='number'
                  min={0}
                  max={60}
                  pattern='[0-9]*'
                  onChange={(e) => onAnswer(interactionKey, Number(e.target.value))}
                  value={value}
                />
              )}
            </Question>
          )
        })}
      </QuestionWrapper>
    </Component>
  )
}

export default CheckoutQuestion
